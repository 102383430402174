import React from 'react'
import { ComponentStyleProps } from 'src/types'

type Props = {
  // content: React.ReactNode
  content: string
} & ComponentStyleProps

export const HTMLContent: React.FC<Props> = (props) => {
  const { content, className } = props
  // eslint-disable-next-line react/no-danger
  return <div className={className} dangerouslySetInnerHTML={{ __html: content }} />
}

const Content: React.FC<Props> = (props) => {
  const { content, className } = props
  return <div className={className}>{content}</div>
}

export default Content
